"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _Export2Excel = require("@/vendor/Export2Excel");
var _Summary = _interopRequireDefault(require("./Summary"));
var _SummaryBook = _interopRequireDefault(require("./SummaryBook"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Summary: _Summary.default,
    SummaryBook: _SummaryBook.default
  },
  data: function data() {
    return {
      visible: false,
      data: {},
      classroom: {},
      indexColBook: []
    };
  },
  methods: {
    handlePrint: function handlePrint() {
      this.$htmlToPaper("print-me");
    },
    calcIndexColBook: function calcIndexColBook(row, preOrderDetails) {
      this.data.report.summaryBook.forEach(function (summary) {
        var detail = preOrderDetails.find(function (e) {
          return e.book.id == summary.book.id;
        });
        row.push(detail ? detail.quantity : 0);
      });
    },
    handleExportGeneral: function handleExportGeneral() {
      var header = ["Sách", "Đơn giá", "SL", "Thành tiền"];
      var total = {
        quantityBook: 0,
        amount: 0
      };
      var data = [];
      this.data.report.summaryBook.forEach(function (element) {
        total.quantityBook += element.quantityBook;
        total.amount += element.quantityBook * element.finalPrice;
        data.push([element.name, element.finalPrice, element.quantityBook, element.finalPrice * element.quantityBook]);
      });
      data.push(["Tổng", "", total.quantityBook, total.amount]);
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        filename: "Phi\u1EBFu \u0111\u1EB7t s\xE1ch ".concat(this.classroom.name)
      });
    },
    handleExportDetail: function handleExportDetail() {
      var _this = this;
      var header = ["Học sinh", "Lớp", "Số lượng sách", "Số tiền"];
      this.data.report.summaryBook.forEach(function (e) {
        header.push(e.name);
      });
      var data = [];
      var quantity = 0;
      var price = 0;
      this.data.report.details.forEach(function (element) {
        var customer = element.customer,
          preOrderDetails = element.preOrderDetails;
        var totalQuantity = 0;
        var totalPrice = 0;
        preOrderDetails.forEach(function (detail) {
          totalQuantity += detail.quantity;
          totalPrice += detail.quantity * detail.finalPrice;
        });
        var row = [customer.name, _this.classroom.name, totalQuantity, totalPrice];
        _this.calcIndexColBook(row, preOrderDetails);
        data.push(row);
        quantity += totalQuantity;
        price += totalPrice;
      });
      data.push(["Tổng", "", quantity, price]);
      (0, _Export2Excel.export_json_to_excel)({
        header: header,
        data: data,
        filename: "Phi\u1EBFu \u0111\u1EB7t s\xE1ch ".concat(this.classroom.name)
      });
    },
    handleDetail: function handleDetail(data, classroom) {
      this.classroom = classroom;
      this.visible = true;
      this.data = (0, _objectSpread2.default)({}, data);
    }
  }
};
exports.default = _default;