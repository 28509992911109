import { render, staticRenderFns } from "./SubjectModal.vue?vue&type=template&id=6eb85666&"
import script from "./SubjectModal.vue?vue&type=script&lang=js&"
export * from "./SubjectModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6eb85666')) {
      api.createRecord('6eb85666', component.options)
    } else {
      api.reload('6eb85666', component.options)
    }
    module.hot.accept("./SubjectModal.vue?vue&type=template&id=6eb85666&", function () {
      api.rerender('6eb85666', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/subject/components/SubjectModal.vue"
export default component.exports