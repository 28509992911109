var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.length
        ? _c("ticket-booking-print", {
            attrs: {
              id: "print-me-" + _vm.grade.name,
              grade: _vm.grade,
              "summary-book": _vm.data,
              linkFooter: _vm.linkFooter,
              linkHeader: _vm.linkHeader,
              note: _vm.note,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }