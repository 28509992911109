"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.classChart = classChart;
exports.getDashboard = getDashboard;
exports.getDashboardPreOrder = getDashboardPreOrder;
exports.gradeChart = gradeChart;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDashboard(params) {
  return (0, _request.default)({
    url: '/dashboard',
    method: 'get',
    params: params
  });
}
function getDashboardPreOrder(params) {
  return (0, _request.default)({
    url: '/dashboard/preOrder',
    method: 'get',
    params: params
  });
}
function classChart(listQuery) {
  return (0, _request.default)({
    url: '/dashboard/preOrder/report/class',
    method: 'get',
    params: listQuery
  });
}
function gradeChart(listQuery) {
  return (0, _request.default)({
    url: '/dashboard/preOrder/report/grade',
    method: 'get',
    params: listQuery
  });
}