var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "top" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Tất cả" } },
            [
              !_vm.submits.length
                ? _c("DetailSummaryTable", {
                    attrs: {
                      "pre-order-id": _vm.preOrderId,
                      "grade-id": undefined,
                      data: _vm.summaryBook,
                    },
                    on: { onValid: _vm.onValidSummaryBook },
                  })
                : _c("HistorySubmit", {
                    attrs: { gradeId: undefined, submits: _vm.submits },
                    on: {
                      onChangeTotalCommission: _vm.onChangeTotalCommission,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.grades, function (item) {
            return _c(
              "el-tab-pane",
              { key: item.id, attrs: { label: item.name, lazy: "" } },
              [
                !_vm.submits.length
                  ? _c("DetailSummaryTable", {
                      attrs: {
                        "pre-order-id": _vm.preOrderId,
                        "grade-id": item.id,
                        data: _vm.summaryBook,
                      },
                      on: { onValid: _vm.onValidSummaryBook },
                    })
                  : _c("HistorySubmit", {
                      attrs: { gradeId: item.id, submits: _vm.submits },
                      on: {
                        onChangeTotalCommission: _vm.onChangeTotalCommission,
                      },
                    }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }