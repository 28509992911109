"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _timeTable = _interopRequireDefault(require("@/views/time-table"));
//
//
//
//
//
//
var _default = {
  components: {
    TimeTable: _timeTable.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    classroom: function classroom(state) {
      return state["class-room"].selected;
    }
  }))
};
exports.default = _default;