var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "margin-bottom": "16px",
          },
        },
        [
          _c(
            "label",
            {
              staticStyle: { "text-transform": "uppercase" },
              attrs: { for: "" },
            },
            [_vm._v("Đợt đặt sách: ")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "300px", "margin-left": "8px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.strategyOrderId,
                callback: function ($$v) {
                  _vm.strategyOrderId = $$v
                },
                expression: "strategyOrderId",
              },
            },
            _vm._l(_vm.strategies, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.strategyOrderId
        ? _c(
            "div",
            [
              _c("strategy-order-detail", {
                attrs: {
                  strategyOrderId: _vm.strategyOrderId,
                  editable: _vm.editable,
                },
              }),
            ],
            1
          )
        : [
            _c(
              "h3",
              { staticClass: "text-center", staticStyle: { color: "red" } },
              [_vm._v("\n      Vui lòng chọn đợt đặt sách\n    ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }