var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết đơn",
        visible: _vm.visible,
        fullscreen: "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("ul", { staticStyle: { "padding-left": "15px" } }, [
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Mã đơn:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.preOrder.code))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Học sinh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.preOrder.studentName))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Phụ huynh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.preOrder.parentName))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("SDT:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.preOrder.parentPhone))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Ngày sinh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.preOrder.studentDob))]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.products,
                border: "",
                fit: "",
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", { attrs: { label: "Tên", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Số lượng" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$formatNumberVN(row.quantity)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Đơn giá" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$formatNumberVN(row.price)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Tổng tiền" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$formatNumberVN(row.totalPrice)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-download", type: "primary" },
              on: { click: _vm.exportExcel },
            },
            [_vm._v("Xuất excel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }