var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.visible, width: "1000px", top: "30px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("span", [
                _vm._v(
                  "Chi tiết (" + _vm._s(_vm.data.strategyOrder.name) + ")"
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("Summary", { attrs: { summary: _vm.data.report.summary } }),
      _vm._v(" "),
      _c("SummaryBook", {
        staticStyle: { "margin-top": "32px" },
        attrs: { "summary-book": _vm.data.report.summaryBook },
      }),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    size: "small",
                  },
                  on: { click: _vm.handlePrint },
                },
                [_vm._v("In phiếu")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                size: "small",
              },
              on: { click: _vm.handleExportGeneral },
            },
            [_vm._v("\n      Xuất excel tổng quan\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                size: "small",
              },
              on: { click: _vm.handleExportDetail },
            },
            [_vm._v("\n      Xuất excel chi tiết\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }