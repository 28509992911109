"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["summaryBook"],
  methods: {
    getSummaries: function getSummaries(_ref) {
      var data = _ref.data;
      var sum = [];
      sum[0] = "Tổng";
      sum[1] = data.reduce(function (prev, cur) {
        return prev + cur.quantityBook;
      }, 0);
      sum[3] = data.reduce(function (prev, cur) {
        return prev + cur.amount;
      }, 0);
      sum[1] = this.$formatNumberVN(sum[1]);
      sum[3] = this.$formatNumberVN(sum[3]);
      return sum;
    }
  }
};
exports.default = _default;