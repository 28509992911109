"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _classRoom = require("@/api/class-room");
var _ClassRoomModal = _interopRequireDefault(require("./components/ClassRoomModal"));
var _strategyOrder = require("@/api/strategy-order");
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    ClassRoomModal: _ClassRoomModal.default
  },
  props: ["schoolId"],
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  watch: {
    schoolId: {
      handler: function handler(val) {
        if (val) {
          this.fetchTableData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.fetchTableData();
    this.fetchStrategyOrder();
  },
  methods: {
    handleUpdate: function handleUpdate(row) {
      this.$store.commit("class-room/setSelected", (0, _objectSpread2.default)({}, row));
      this.$store.commit("school/setSelected", (0, _objectSpread2.default)({}, row.school));
      this.$refs["dialog"].handleUpdate(row);
    },
    handleCreate: function handleCreate() {
      this.$refs["dialog"].handleCreate();
    },
    handleUnBlock: function handleUnBlock(row) {
      this.$confirm("Khách hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {});
    },
    handleBlock: function handleBlock(row) {
      var _this = this;
      this.$confirm("Lớp học này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _classRoom.classRoomApi.remove(row.id).then(function () {
          _this.$notify.success({
            message: "Xoá thành công",
            title: "Thông báo"
          });
          _this.fetchTableData();
        });
      });
    },
    fetchTableData: function fetchTableData() {
      var _this2 = this;
      this.listLoading = true;
      _classRoom.classRoomApi.findAll((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        schoolId: this.schoolId
      })).then(function (res) {
        _this2.tableData = res.data.data;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    fetchStrategyOrder: function fetchStrategyOrder() {
      var _this3 = this;
      _strategyOrder.strategyOrderApi.findAll({
        page: 1,
        limit: 10
      }).then(function (res) {
        if (!(0, _isEmpty.default)(res.data)) {
          _this3.$store.commit("strategyOrder/setSelected", res.data);
        }
      });
    }
  }
};
exports.default = _default;