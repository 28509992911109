"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  selected: {}
};
var mutations = {
  setSelected: function setSelected(state, val) {
    state.selected = val;
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations
};
exports.default = _default;