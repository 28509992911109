"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dataSouce: {},
      dataStrategy: {}
    };
  },
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    getData: function getData() {
      var _this = this;
      dashboard.getDashboard().then(function (res) {
        _this.dataSouce = res.data;
      });
    },
    getDataStrategy: function getDataStrategy() {
      var _this2 = this;
      dashboard.getDashboardPreOrder().then(function (res) {
        _this2.dataStrategy = res.data;
      });
    }
  },
  created: function created() {
    this.getData();
    this.getDataStrategy();
  }
};
exports.default = _default;