"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _teacher = require("@/api/teacher");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      form: {},
      visible: false,
      status: "create",
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        }
      },
      schools: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    classrooms: function classrooms(state) {
      return state["class-room"].list;
    }
  })), (0, _vuex.mapGetters)(["token"])),
  created: function created() {
    this.$store.dispatch("class-room/fetchList");
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.$set(this.form, "avatar", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.$message.error("Ảnh không đúng định dạng!");
      }
      return isJPG;
    },
    handleCreate: function handleCreate() {
      this.status = "create";
      this.visible = true;
      this.form = {};
    },
    handleUpdate: function handleUpdate(data) {
      this.form = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        classroomId: data.classroom && data.classroom.id
      });
      this.status = "update";
      this.visible = true;
    },
    createData: function createData() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            teacher: (0, _objectSpread2.default)({}, _this.form),
            classroomId: _this.form.classroomId
          };
          _this.loading = true;
          _teacher.teacherApi.create(data).then(function () {
            _this.$notify.success({
              message: "Tạo thành công",
              title: "Thông báo"
            });
            _this.visible = false;
            _this.$emit("submit:success");
          }).finally(function () {
            return _this.loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            teacher: (0, _objectSpread2.default)({}, _this2.form),
            classroomId: _this2.form.classroomId
          };
          _this2.loading = true;
          _teacher.teacherApi.update(_this2.form.id, data).then(function () {
            _this2.$notify.success({
              message: "Cập nhật thành công",
              title: "Thông báo"
            });
            _this2.visible = false;
            _this2.$emit("submit:success");
          }).finally(function () {
            return _this2.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;