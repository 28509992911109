var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "summary-grade" } },
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "top" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Tất cả" } },
            [
              _c("summary-grade-table", {
                attrs: { gradeId: undefined, summaryGrade: _vm.summaryGrade },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.grades, function (item) {
            return _c(
              "el-tab-pane",
              { key: item.id, attrs: { label: item.name } },
              [
                _c("summary-grade-table", {
                  attrs: { gradeId: item.id, summaryGrade: _vm.summaryGrade },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }