"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    permission: true,
    title: "Dashboard",
    icon: "dashboard"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "Dashboard",
    meta: {
      permission: true,
      title: "Dashboard"
    }
  }]
}, {
  path: "/preOrder",
  component: _layout.default,
  redirect: "/preOrder/strategy",
  permission: true,
  meta: {
    title: "Đơn hàng",
    icon: "dolly-flatbed-solid"
  },
  children: [{
    path: "strategy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/strategy-order"));
      });
    },
    name: "order-strategy",
    meta: {
      title: "Đặt sách"
    }
  }, {
    path: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/strategy-detail"));
      });
    },
    name: "order-strategy-detail",
    // hidden: true,
    meta: {
      title: "Chi tiết đợt đặt sách"
      // title: "Đợt đặt sách",
    }
  }, {
    path: "submit-pre-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/submit-pre-order"));
      });
    },
    name: "submit-pre-order",
    meta: {
      title: "Nộp bản đặt"
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/pre-order"));
      });
    },
    name: "pre-order",
    meta: {
      permission: true,
      title: "Đơn đặt sách"
    }
  }]
}, {
  path: "/contact-book",
  component: _layout.default,
  redirect: "/contact-book/index",
  permission: true,
  meta: {
    title: "Sổ liên lạc điện tử",
    permission: true,
    icon: "comments-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/contact-book"));
      });
    },
    name: "contact-book-index",
    meta: {
      permission: true,
      title: "Sổ liên lạc điện tử"
    }
  }, {
    path: "teacher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/teacher"));
      });
    },
    name: "teacher-index",
    meta: {
      title: "Quản lý Giáo viên"
    }
  }, {
    path: "student",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/student"));
      });
    },
    name: "student-index",
    meta: {
      title: "Quản lý Học sinh"
    }
  }, {
    path: "subject",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/subject"));
      });
    },
    name: "subject-index",
    meta: {
      title: "Quản lý Môn học"
    }
  }, {
    path: "class-room",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/class-room"));
      });
    },
    name: "class-room-index",
    meta: {
      title: "Quản lý Lớp học"
    }
  }]
},
// {
//   path: "/teacher",
//   component: Layout,
//   redirect: "/teacher/index",
//   permission: true,
//   meta: {
//     title: "Giáo viên",
//     icon: "graduation-cap-solid",
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/teacher"),
//       name: "teacher-index",
//       meta: {
//         title: "Quản lý Giáo viên",
//       },
//     },
//   ],
// },

// {
//   path: "/student",
//   component: Layout,
//   redirect: "/student/index",
//   permission: true,
//   meta: {
//     title: "Học sinh",
//     icon: "book-reader-solid",
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/student"),
//       name: "student-index",
//       meta: {
//         title: "Quản lý Học sinh",
//       },
//     },
//   ],
// },

// {
//   path: "/subject",
//   component: Layout,
//   redirect: "/subject/index",
//   permission: true,
//   meta: {
//     title: "Môn học",
//     icon: "dashboard",
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/subject"),
//       name: "subject-index",
//       meta: {
//         title: "Quản lý Môn học",
//       },
//     },
//   ],
// },

// {
//   path: "/class-room",
//   component: Layout,
//   redirect: "/class-room/index",
//   permission: true,
//   meta: {
//     title: "Lớp học",
//     icon: "clipboard-list-solid",
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/class-room"),
//       name: "class-room-index",
//       meta: {
//         title: "Quản lý Lớp học",
//       },
//     },
//   ],
// },

{
  path: "/commission",
  component: _layout.default,
  redirect: "/commission/index",
  permission: true,
  meta: {
    title: "Hoa hồng",
    icon: "dashboard"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/commission"));
      });
    },
    name: "commission-index",
    meta: {
      title: "Hoa hồng"
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  meta: {
    title: "Cá nhân",
    icon: "qq"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      title: "Trang cá nhân"
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;