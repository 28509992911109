"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var schoolApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/school",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/school",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/school/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/school/".concat(id, "/delete"),
      method: "post"
    });
  }
};
exports.schoolApi = schoolApi;