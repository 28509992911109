"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _default = {
  props: ["data", "gradeId"],
  computed: {
    transformDataSubmit: function transformDataSubmit() {
      var _this = this;
      var data = [];
      if (this.gradeId === undefined) {
        data = this.data;
      } else {
        data = this.data.filter(function (e) {
          return e.book.grade && e.book.grade.id == _this.gradeId;
        });
      }
      return data;
    }
  },
  watch: {
    data: {
      handler: function handler(val) {
        val.forEach(function (element) {
          element.quantityDivision = element.quantityBook - element.quantityCompany;
        });
      },
      deep: true
    }
  },
  methods: {
    getSummaries: function getSummaries(_ref) {
      var data = _ref.data;
      var sum = [];
      sum[0] = "Tổng";
      sum[2] = 0;
      sum[3] = 0;
      sum[4] = 0;
      sum[5] = 0;
      sum[6] = 0;
      sum[7] = 0;
      data.forEach(function (element) {
        sum[2] += element.quantityBook;
        sum[3] += element.amount;
        sum[4] += element.quantityDivision;
        sum[5] += element.divisionCommission;
        sum[6] += +element.quantityCompany;
        sum[7] += element.companyCommission;
      });
      sum[2] = this.$formatNumberVN(sum[2]);
      sum[3] = this.$formatNumberVN(sum[3]);
      sum[4] = this.$formatNumberVN(sum[4]);
      sum[5] = this.$formatNumberVN(sum[5]);
      sum[6] = this.$formatNumberVN(sum[6]);
      sum[7] = this.$formatNumberVN(sum[7]);
      return sum;
    }
  }
};
exports.default = _default;