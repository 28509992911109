var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.transformDataSubmit,
            border: "",
            fit: "",
            "row-key": "id",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
        },
        [
          _c("el-table-column", { attrs: { label: "Sách", prop: "name" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đơn giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatNumberVN(row.finalPrice)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SL", prop: "quantityBook" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatNumberVN(row.quantityBook)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thành tiền", prop: "quantityBook" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatNumberVN(row.amount)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Phòng" } },
            [
              _c("el-table-column", {
                attrs: { label: "SL nộp" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          attrs: { disabled: "" },
                          model: {
                            value: row.quantityDivision,
                            callback: function ($$v) {
                              _vm.$set(row, "quantityDivision", $$v)
                            },
                            expression: "row.quantityDivision",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Hoa hồng" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toThousandFilter")(row.divisionCommission)
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Công ty" } },
            [
              _c("el-table-column", {
                attrs: { label: "SL nộp" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                                max: row.quantityBook,
                                min: 0,
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false,max: row.quantityBook, min: 0}",
                            },
                          ],
                          model: {
                            value: row.quantityCompany,
                            callback: function ($$v) {
                              _vm.$set(row, "quantityCompany", $$v)
                            },
                            expression: "row.quantityCompany",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Hoa hồng" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toThousandFilter")(row.companyCommission)
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }