"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.remove = remove;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(params) {
  return (0, _request.default)({
    url: "/timetable",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/timetable",
    method: "post",
    data: data
  });
}
function update(id, data) {
  return (0, _request.default)({
    url: "/timetable/".concat(id, "/update"),
    method: "post",
    data: data
  });
}
function remove(id) {
  return (0, _request.default)({
    url: "/timetable/".concat(id, "/delete"),
    method: "post"
  });
}