"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolContactBookApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var schoolContactBookApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/schoolContactBookTransaction",
      params: params
    });
  }
};
exports.schoolContactBookApi = schoolContactBookApi;