"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gradeSubject = require("@/api/grade-subject");
var _SubjectModal = _interopRequireDefault(require("./SubjectModal"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SubjectModal: _SubjectModal.default
  },
  props: ["gradeId"],
  data: function data() {
    return {
      tableData: [],
      loading: false
    };
  },
  watch: {
    gradeId: {
      handler: function handler(val) {
        this.fetchData();
      },
      immediate: true
    }
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.loading = true;
      _gradeSubject.subjectApi.findAll({
        gradeId: this.gradeId
      }).then(function (res) {
        _this.tableData = res.data.gradeSubjects;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.modal.handleAdd();
    },
    handleEdit: function handleEdit(row) {
      this.$refs.modal.handleEdit(row);
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;
      this.$confirm("Môn học này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          gradeSubject: {
            isDeleted: true
          }
        };
        _gradeSubject.subjectApi.update(row.id, data).then(function () {
          row.isBlock = true;
          _this2.$message({
            type: "success",
            message: "Xoá  thành công"
          });
          _this2.fetchData();
        });
      });
    }
  }
};
exports.default = _default;