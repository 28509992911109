"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
function formatCurrency(n) {
  return new Intl.NumberFormat("Vi", {
    style: "currency",
    currency: "VND"
  }).format(n);
}
function formatNumberVN(n) {
  return (+n || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
}