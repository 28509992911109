"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _commission = require("@/api/commission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["type"],
  data: function data() {
    return {
      tableData: [],
      loading: false
    };
  },
  watch: {
    type: {
      handler: function handler(val) {
        if (val == "company") {
          this.fetchDataCompany();
        } else {
          this.fetchDataDivision();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchDataCompany: function fetchDataCompany() {
      var _this = this;
      this.loading = true;
      _commission.commissionCompanyApi.find().then(function (res) {
        _this.tableData = res.data;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    fetchDataDivision: function fetchDataDivision() {
      var _this2 = this;
      this.loading = true;
      _commission.commissionDivisionApi.find().then(function (res) {
        _this2.tableData = res.data.map(function (e) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, e), {}, {
            value: e.school
          });
        });
      }).finally(function () {
        return _this2.loading = false;
      });
    }
  }
};
exports.default = _default;