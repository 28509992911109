"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _strategyOrder = require("@/api/strategy-order");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var form = {
  name: "",
  closeAt: (0, _moment.default)().unix() * 1000,
  closeTeacherAt: (0, _moment.default)().unix() * 1000,
  closeCustomerAt: (0, _moment.default)().unix() * 1000
};
var _default = {
  data: function data() {
    var _this = this;
    var validateCloseAt = function validateCloseAt(rule, value, callback) {
      var current = (0, _moment.default)().format("YYYY-MM-DD");
      value = _moment.default.unix(value / 1000).format("YYYY-MM-DD");
      var closeTeacherAt = _moment.default.unix(_this.form.closeTeacherAt / 1000).format("YYYY-MM-DD");
      if (value < current) {
        callback(new Error("Ngày kết thúc không bé hơn ngày hiện tại"));
      } else if (value > closeTeacherAt) {
        callback(new Error("Ngày kết thúc của học sinh phải bé hơn ngày kết thúc của giáo viên"));
      } else {
        callback();
      }
    };
    var validatecloseTeacherAt = function validatecloseTeacherAt(rule, value, callback) {
      value = _moment.default.unix(value / 1000).format("YYYY-MM-DD");
      var closeCustomerAt = _moment.default.unix(_this.form.closeCustomerAt / 1000).format("YYYY-MM-DD");
      if (value < closeCustomerAt) {
        callback(new Error("Ngày kết thúc này không bé hơn ngày kết thúc học sinh"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      form: (0, _objectSpread2.default)({}, form),
      visible: false,
      status: "create",
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        closeAt: {
          required: true,
          validator: validateCloseAt,
          trigger: "submit"
        },
        closeCustomerAt: {
          required: true,
          message: "Trường này là bắt buộc",
          trigger: "submit"
        },
        closeTeacherAt: {
          required: true,
          validator: validatecloseTeacherAt,
          trigger: "submit"
        }
      }
    };
  },
  created: function created() {},
  methods: {
    handleCreate: function handleCreate() {
      this.status = "create";
      this.visible = true;
      this.form = (0, _objectSpread2.default)({}, form);
    },
    handleUpdate: function handleUpdate(data) {
      this.form = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        closeAt: data.closeAt * 1000,
        closeTeacherAt: data.closeTeacherAt * 1000,
        closeCustomerAt: data.closeCustomerAt * 1000
      });
      this.status = "update";
      this.visible = true;
    },
    createData: function createData() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            strategyOrder: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.form), {}, {
              closeAt: _this2.form.closeAt / 1000,
              closeTeacherAt: _this2.form.closeTeacherAt / 1000,
              closeCustomerAt: _this2.form.closeCustomerAt / 1000
            })
          };
          _this2.loading = true;
          _strategyOrder.strategyOrderApi.create(data).then(function () {
            _this2.$notify.success({
              message: "Tạo thành công",
              title: "Thông báo"
            });
            _this2.visible = false;
            _this2.$emit("submit:success");
          }).finally(function () {
            return _this2.loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            strategyOrder: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this3.form), {}, {
              closeAt: _this3.form.closeAt / 1000,
              closeTeacherAt: _this3.form.closeTeacherAt / 1000,
              closeCustomerAt: _this3.form.closeCustomerAt / 1000
            })
          };
          _this3.loading = true;
          _strategyOrder.strategyOrderApi.update(_this3.form.id, data).then(function () {
            _this3.$notify.success({
              message: "Cập nhật thành công",
              title: "Thông báo"
            });
            _this3.visible = false;
            _this3.$emit("submit:success");
          }).finally(function () {
            return _this3.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;