var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.summaryBook,
            border: "",
            fit: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Tên sách",
              prop: "name",
              "class-name": "text-bold",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Số lượng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toThousandFilter")(row.quantityBook)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đơn giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toThousandFilter")(row.finalPrice)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thành tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toThousandFilter")(row.amount)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }