var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticStyle: { height: "100%" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "tr",
          { key: index },
          [
            !item.colSpan
              ? [
                  _c("td", [_vm._v(_vm._s(item.stt))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.bookName))]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          item.finalPrice
                            ? _vm.$formatNumberVN(item.finalPrice)
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }),
                ]
              : [
                  _c(
                    "td",
                    { staticClass: "grade-row", attrs: { colspan: "4" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.categoryName) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("STT")]),
        _vm._v(" "),
        _c("th", [_vm._v("Tên sách")]),
        _vm._v(" "),
        _c("th", [_vm._v("Giá sách")]),
        _vm._v(" "),
        _c("th", [_vm._v("Số lượng")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }