"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _SubjectTable = _interopRequireDefault(require("./components/SubjectTable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SubjectTable: _SubjectTable.default
  },
  data: function data() {
    return {
      tabName: "",
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    tableData: function tableData(state) {
      return state.user.info.grades;
    }
  })),
  methods: {
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    }
  }
};
exports.default = _default;