"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _objectSpread3 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["submits", "gradeId"],
  computed: (0, _objectSpread3.default)((0, _objectSpread3.default)({}, (0, _vuex.mapState)({
    grades: function grades(state) {
      return state.grade.list;
    }
  })), {}, {
    data: function data() {
      var books = [];
      this.submits.forEach(function (e) {
        var quantityType = e.type == "COMPANY" ? "quantityCompany" : "quantityDivision";
        var commissionType = e.type == "COMPANY" ? "companyCommission" : "divisionCommission";
        e.schoolSubmitPreOrderDetails.forEach(function (element) {
          var book = books.find(function (e) {
            return e.id == element.book.id;
          });
          if (book) {
            book[quantityType] += element.quantity;
            book[commissionType] += element.commission;
            book.quantity += element.quantity;
          } else {
            var _objectSpread2;
            books.push((0, _objectSpread3.default)((_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, quantityType, element.quantity), (0, _defineProperty2.default)(_objectSpread2, commissionType, element.commission), (0, _defineProperty2.default)(_objectSpread2, quantityType == "quantityCompany" ? "quantityDivision" : "quantityCompany", 0), (0, _defineProperty2.default)(_objectSpread2, commissionType == "companyCommission" ? "divisionCommission" : "companyCommission", 0), (0, _defineProperty2.default)(_objectSpread2, "quantity", element.quantity), _objectSpread2), element.book));
          }
        });
      });
      return books;
    },
    transformDataSubmit: function transformDataSubmit() {
      var _this = this;
      var data = [];
      if (!this.data || !this.data.length) {
        return data;
      }
      if (this.gradeId === undefined) {
        data = this.data;
      } else {
        data = this.data.filter(function (e) {
          return e.grade && e.grade.id == _this.gradeId;
        });
      }
      return data;
    }
  }),
  created: function created() {
    this.$store.dispatch("grade/fetchList");
  },
  methods: {
    getSummaries: function getSummaries(_ref) {
      var data = _ref.data;
      var sum = [];
      sum[0] = "Tổng";
      sum[1] = 0;
      sum[2] = 0;
      sum[3] = 0;
      sum[4] = 0;
      sum[5] = 0;
      data.forEach(function (element) {
        sum[1] += element.quantityBook;
        sum[2] += element.quantityDivision;
        sum[3] += element.divisionCommission;
        sum[4] += element.quantityCompany;
        sum[5] += element.companyCommission;
      });
      this.$emit("onChangeTotalCommission", sum[3] + sum[5]);
      sum[1] = this.$formatNumberVN(sum[1]);
      sum[2] = this.$formatNumberVN(sum[2]);
      sum[3] = this.$formatNumberVN(sum[3]);
      sum[4] = this.$formatNumberVN(sum[4]);
      sum[5] = this.$formatNumberVN(sum[5]);
      return sum;
    }
  }
};
exports.default = _default;