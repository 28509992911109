var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.data,
            border: "",
            fit: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
        },
        [
          _c("el-table-column", { attrs: { label: "Code", prop: "code" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Học sinh", prop: "studentName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Phụ huynh", prop: "parentName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SDT", prop: "parentPhone" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày sinh", prop: "studentDob" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Tổng sách", prop: "totalProduct" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Tổng tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatNumberVN(row.totalPrice)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thao tác" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết đơn",
                          placement: "top",
                          effect: "dark",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-info",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickDetail(row.code)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.editable
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Xóa đơn",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini",
                                loading: row.loadingCancel,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onCancel(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.onPagination,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }