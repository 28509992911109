"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _ClassroomReportTable = _interopRequireDefault(require("./ClassroomReportTable.vue"));
var _default = {
  components: {
    ClassroomReportTable: _ClassroomReportTable.default
  },
  props: ["preOrderId", "isSubmitted", "editable"],
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    grades: function grades(state) {
      return state.grade.list;
    }
  })),
  created: function created() {
    this.$store.dispatch("grade/fetchList");
  }
};
exports.default = _default;