"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commissionDivisionApi = exports.commissionCompanyApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var commissionDivisionApi = {
  find: function find() {
    return (0, _request.default)({
      url: "/commissionDivision"
    });
  }
};
exports.commissionDivisionApi = commissionDivisionApi;
var commissionCompanyApi = {
  find: function find() {
    return (0, _request.default)({
      url: "/commissionCompany"
    });
  }
};
exports.commissionCompanyApi = commissionCompanyApi;