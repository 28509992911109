var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ticket-booking-print" }, [
      _vm.linkHeader
        ? _c("img", {
            staticStyle: {
              width: "100%",
              "max-height": "100px",
              "margin-bottom": "16px",
            },
            attrs: { src: _vm.$baseUrlMedia + _vm.linkHeader, alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [
        _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "info-item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("p", { staticClass: "font-12 font-bold m-0 text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.transformGradeNameTicket) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-item" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("p", { staticClass: "font-bold m-0 font-12 text-center" }, [
              _vm._v(
                "\n            Năm học: " +
                  _vm._s(_vm.currentYear) +
                  " - " +
                  _vm._s(_vm.nextYear) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-block" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ticket-booking-table", { attrs: { data: _vm.table1 } }),
            _vm._v(" "),
            _c("ticket-booking-table", { attrs: { data: _vm.table2 } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "note font-12",
            staticStyle: { "margin-top": "12px" },
          },
          [
            _c(
              "span",
              { staticClass: "font-bold", staticStyle: { color: "#489620" } },
              [_vm._v("* Chú ý")]
            ),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.note) } }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("footer", [
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "footer-image" }, [
          _vm.linkFooter
            ? _c("img", {
                staticStyle: {
                  width: "70%",
                  margin: "0px auto",
                  display: "flex",
                },
                attrs: { src: _vm.$baseUrlMedia + _vm.linkFooter, alt: "" },
              })
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c("div", { staticClass: "prefix" }, [
        _c("label", { attrs: { for: "" } }, [_vm._v("Trường:")]),
        _vm._v(" "),
        _c("div", { staticClass: "dots" }),
      ]),
      _vm._v(" "),
      _c(
        "h4",
        {
          staticClass: "font-12 font-bold m-0 text-center",
          staticStyle: { margin: "0" },
        },
        [_vm._v("\n            PHIẾU MUA SÁCH GIÁO KHOA\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prefix" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Huyện (TX, TP):")]),
      _vm._v(" "),
      _c("div", { staticClass: "dots" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prefix" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Họ và tên:")]),
      _vm._v(" "),
      _c("div", { staticClass: "dots" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sign" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "span",
          { staticClass: "font-12", staticStyle: { "font-weight": "300" } },
          [_vm._v("Ngày...tháng...năm......")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sign" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Giấy xác nhận của GVCN"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Phụ huynh học sinh ký tên"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Học sinh đăng ký"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }