"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preOrderDetailApi = exports.preOrderApi = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var preOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/preOrder",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/preOrder",
      data: data,
      method: "post"
    });
  },
  findByClassRoom: function findByClassRoom(strategyPreOrderId, classroomId, params) {
    return (0, _request.default)({
      url: "/preOrder/list",
      params: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        strategyPreOrderId: strategyPreOrderId,
        classroomId: classroomId
      })
    });
  },
  submit: function submit(preOrderId) {
    return (0, _request.default)({
      url: "/preOrder/".concat(preOrderId, "/submit"),
      method: "post"
    });
  },
  cancel: function cancel(preOrderId) {
    return (0, _request.default)({
      url: "/preOrder/".concat(preOrderId, "/cancel"),
      method: "post"
    });
  },
  ubSubmit: function ubSubmit(preOrderId) {
    return (0, _request.default)({
      url: "/preOrder/".concat(preOrderId, "/unSubmit"),
      method: "post"
    });
  },
  add: function add(id, data) {
    return (0, _request.default)({
      url: "/preOrder/".concat(id, "/add/detail"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id, data) {
    return (0, _request.default)({
      url: "/preOrder/".concat(id, "/delete"),
      data: data,
      method: "post"
    });
  }
};
exports.preOrderApi = preOrderApi;
var preOrderDetailApi = {
  updateDetail: function updateDetail(id, data) {
    return (0, _request.default)({
      url: "/preOrderDetail/".concat(id, "/quantity"),
      data: data,
      method: "post"
    });
  },
  removeDetail: function removeDetail(id, data) {
    return (0, _request.default)({
      url: "/preOrderDetail/".concat(id, "/delete"),
      data: data,
      method: "post"
    });
  }
};
exports.preOrderDetailApi = preOrderDetailApi;