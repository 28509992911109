"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _classRoom = require("@/api/class-room");
var _StudentTab = _interopRequireDefault(require("./StudentTab"));
var _TimeTableTab = _interopRequireDefault(require("./TimeTableTab"));
var _vuex = require("vuex");
var _teacher = require("@/api/teacher");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    StudentTab: _StudentTab.default,
    TimeTableTab: _TimeTableTab.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    grades: function grades(state) {
      return state.grade.list;
    }
  })),
  props: ["schoolId"],
  data: function data() {
    return {
      modalWidth: "500px",
      tabName: "info",
      loading: false,
      form: {},
      visible: false,
      status: "create",
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        gradeId: {
          required: true,
          message: "Vui lòng chọn khối",
          trigger: "submit"
        },
        code: {
          required: true,
          message: "Trường này là bắt buộc",
          trigger: "submit"
        }
      },
      schools: [],
      teachers: []
    };
  },
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "info":
          this.modalWidth = "500px";
          break;
        case "student":
        case "timetable":
          this.modalWidth = "900px";
          break;
        default:
          break;
      }
    },
    schoolId: {
      handler: function handler(val) {
        if (val) {
          this.$set(this.form, "schoolId", val);
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.$store.dispatch("grade/fetchList");
    this.fetchTeacher();
  },
  methods: {
    fetchTeacher: function fetchTeacher() {
      var _this = this;
      _teacher.teacherApi.findAll().then(function (res) {
        return _this.teachers = res.data.data;
      });
    },
    handleCreate: function handleCreate() {
      this.status = "create";
      this.tabName = "info";
      this.visible = true;
      this.form = {};
    },
    handleUpdate: function handleUpdate(data) {
      this.form = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        teacherId: data.teacher && data.teacher.id,
        gradeId: data.grade && data.grade.id
      });
      this.status = "update";
      this.visible = true;
      this.tabName = "info";
    },
    createData: function createData() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            classroom: (0, _objectSpread2.default)({}, _this2.form),
            teacherId: _this2.form.teacherId,
            gradeId: _this2.form.gradeId
          };
          _this2.loading = true;
          _classRoom.classRoomApi.create(data).then(function () {
            _this2.$notify.success({
              message: "Tạo thành công",
              title: "Thông báo"
            });
            _this2.visible = false;
            _this2.$emit("submit:success");
          }).finally(function () {
            return _this2.loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            classroom: (0, _objectSpread2.default)({}, _this3.form),
            teacherId: _this3.form.teacherId,
            gradeId: _this3.form.gradeId
          };
          _this3.loading = true;
          _classRoom.classRoomApi.update(_this3.form.id, data).then(function () {
            _this3.$notify.success({
              message: "Cập nhật thành công",
              title: "Thông báo"
            });
            _this3.visible = false;
            _this3.$emit("submit:success");
          }).finally(function () {
            return _this3.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;