var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết đặt sách " + _vm.classroom.name,
        visible: _vm.visible,
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("pre-order-class-room-table", {
            attrs: {
              data: _vm.preOrderList,
              query: _vm.query,
              onPagination: _vm.fetchPreOrderList,
              total: _vm.total,
              onClickDetail: _vm.handleClickDetail,
              onCancel: _vm.handleCancel,
              editable: _vm.editable,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportExcel } },
            [_vm._v("Xuất excel")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("pre-order-detail-modal", { ref: "PreOrderDetailModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }