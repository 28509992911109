"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _grade = require("@/api/grade");
var _strategyOrder = require("@/api/strategy-order");
var _TicketBookingPrint = _interopRequireDefault(require("./TicketBookingPrint.vue"));
var _request = _interopRequireDefault(require("@/utils/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TicketBookingPrint: _TicketBookingPrint.default
  },
  props: ["strategyId", "grade", "linkHeader", "linkFooter", "note"],
  data: function data() {
    return {
      // grade: {},
      data: [],
      gradeIndex: null
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    handlePrint: function handlePrint() {
      this.$htmlToPaper("print-me-".concat(this.grade.name));
    },
    fetchData: function fetchData() {
      var _this = this;
      _strategyOrder.strategyOrderApi.findBooks(this.strategyId, {
        gradeId: this.grade.id
      }).then(function (res) {
        _this.data = res.data;
      });
    }
  }
};
exports.default = _default;