"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "formatTime", {
  enumerable: true,
  get: function get() {
    return _utils.formatTime;
  }
});
exports.numberFormatter = numberFormatter;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
exports.stripHTML = stripHTML;
Object.defineProperty(exports, "stripHtml", {
  enumerable: true,
  get: function get() {
    return _utils.stripHtml;
  }
});
exports.timeAgo = timeAgo;
exports.toThousandFilter = toThousandFilter;
exports.uppercaseFirst = uppercaseFirst;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
// import parseTime, formatTime and set to filter

function stripHTML(html) {
  return stripHtml(html);
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

/**
 * @param {number} time
 */
function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
function numberFormatter(num, digits) {
  var si = [{
    value: 1e18,
    symbol: "E"
  }, {
    value: 1e15,
    symbol: "P"
  }, {
    value: 1e12,
    symbol: "T"
  }, {
    value: 1e9,
    symbol: "G"
  }, {
    value: 1e6,
    symbol: "M"
  }, {
    value: 1e3,
    symbol: "k"
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}