"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueAutonumeric = _interopRequireDefault(require("vue-autonumeric"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = _vue.default.extend({
  components: {
    VueAutoNumeric: _vueAutonumeric.default
  },
  props: ["value", "options"],
  methods: {
    handleInput: function handleInput(value) {
      this.$emit("input", value);
    }
  }
});
exports.default = _default;