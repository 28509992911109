var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "preorder-grade" } },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "0", label: "Tất cả" } },
            [
              _c("pre-order-table", {
                attrs: {
                  gradeId: undefined,
                  preOrder: _vm.preOrder,
                  data: _vm.strategyOrderBooksSelect,
                  loading: _vm.loading,
                  editable: _vm.editable,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.grades, function (item) {
            return _c(
              "el-tab-pane",
              {
                key: item.id,
                attrs: {
                  label: item.name,
                  name: "" + (item.id + ""),
                  lazy: "",
                },
              },
              [
                _c("pre-order-table", {
                  attrs: {
                    gradeId: item.id,
                    preOrder: _vm.preOrder,
                    data: _vm.strategyOrderBooksSelect,
                    loading: _vm.loading,
                    editable: _vm.editable,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isSubmittedStrategy
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-top": "32px",
              },
            },
            [
              _c(
                "div",
                [
                  _vm.visibleSubmit
                    ? _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingSubmit,
                          },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("\n        Nộp tất cả\n      ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "danger", loading: _vm.loadingSubmit },
                          on: { click: _vm.removePreOrder },
                        },
                        [_vm._v("\n        Hủy nộp tất cả\n      ")]
                      ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }