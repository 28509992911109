var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          convertDataBar: _vm.convertDataBar,
          "data-chart": _vm.dataChart,
          "pointer-name": "Lớp",
          "attr-get": "sale",
          format: "currency",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.dataChart = $event
          },
          "update:data-chart": function ($event) {
            _vm.dataChart = $event
          },
        },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v("Biểu đồ đặt sách theo lớp"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }