"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentTypeTrans = exports.PaymentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _PaymentTypeTrans;
var PaymentType;
exports.PaymentType = PaymentType;
(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["VNPAY"] = "VNPAY";
  PaymentType["TeacherPay"] = "TEACHER_PAY";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PaymentTypeTrans = (_PaymentTypeTrans = {}, (0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.Cash, 'Tiền mặt'), (0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.VNPAY, 'VnPay'), _PaymentTypeTrans);
exports.PaymentTypeTrans = PaymentTypeTrans;