"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _date = require("@/utils/date");
var _Class = _interopRequireDefault(require("./components/chart/Class"));
var _Grade = _interopRequireDefault(require("./components/chart/Grade"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    ClassChart: _Class.default,
    GradeChart: _Grade.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};
exports.default = _default;