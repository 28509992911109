"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.fill");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _TicketBookingTable = _interopRequireDefault(require("./TicketBookingTable.vue"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    TicketBookingTable: _TicketBookingTable.default
  },
  props: {
    linkFooter: {
      type: String,
      required: true,
      default: function _default() {
        return [];
      }
    },
    linkHeader: {
      type: String,
      required: true,
      default: function _default() {
        return [];
      }
    },
    summaryBook: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    grade: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    note: {
      type: String,
      required: true,
      default: ""
    }
  },
  computed: {
    data: function data() {
      // const preOrderCategories = [];
      // const data = [];
      // this.summaryBook.forEach((element, i) => {
      //   let category = preOrderCategories.find(
      //     (e) => e.id == element.book.strategyOrderDetails[0].id
      //   );
      //   if (!category) {
      //     category = element.book.strategyOrderDetails[0];
      //     category.books = [];
      //     preOrderCategories.push(category);
      //   }
      //   category.books.push({
      //     amount: element.amount,
      //     bookName: element.name,
      //     quantity: element.quantityBook,
      //     finalPrice: element.finalPrice,
      //   });
      // });
      var data = [];
      var stt = 1;
      this.summaryBook.forEach(function (category) {
        data.push({
          categoryName: category.title,
          colSpan: true
        });
        stt = 1;
        category.books.forEach(function (book) {
          data.push({
            stt: stt++,
            bookName: book.name,
            finalPrice: book.finalPrice
          });
        });
      });
      var categoryPath = [];
      data.forEach(function (element, i) {
        if (element.colSpan) {
          categoryPath.push(i);
        }
      });
      var data1 = [];
      var data2 = [];
      // if (data.length > 10 && categoryPath.length >= 2) {
      //   const index = categoryPath[Math.floor(categoryPath.length / 2)];
      //   data1 = data.slice(0, index);
      //   data2 = data.slice(index);
      // } else {
      //   data1 = data;
      // }
      var indexCut = 25;
      if (data.length > indexCut) {
        var index = categoryPath[Math.floor(categoryPath.length / 2)];
        if (Math.max(indexCut, index) == index && Math.floor(categoryPath.length / 2) - 1) {
          index = categoryPath[Math.floor(categoryPath.length / 2) - 1];
        }
        var indexSlice = Math.min(index, indexCut);
        data1 = data.slice(0, indexSlice);
        data2 = data.slice(indexSlice);
      } else {
        data1 = data;
      }
      if (data1.length > data2.length) {
        data2 = [].concat((0, _toConsumableArray2.default)(data2), (0, _toConsumableArray2.default)(new Array(data1.length - data2.length).fill({})));
      } else {
        data1 = [].concat((0, _toConsumableArray2.default)(data1), (0, _toConsumableArray2.default)(new Array(data2.length - data1.length).fill({})));
      }
      return {
        data1: data1,
        data2: data2
      };
    },
    table1: function table1() {
      return this.data.data1;
    },
    table2: function table2() {
      return this.data.data2;
    },
    transformGradeNameTicket: function transformGradeNameTicket() {
      var grade = this.grade.name.split(" ");
      grade[1] = +grade[1] + 1;
      return grade.join(" ");
    },
    currentYear: function currentYear() {
      return (0, _moment.default)().format("YYYY");
    },
    nextYear: function nextYear() {
      return (0, _moment.default)().add(1, "year").format("YYYY");
    }
  }
};
exports.default = _default2;