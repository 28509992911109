var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "create" ? "Thêm chiến dịch" : "Cập nhật chiến dịch",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Ngày kết thúc của học sinh",
                prop: "closeCustomerAt",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "timestamp",
                  format: "dd/MM/yyyy",
                  type: "date",
                },
                model: {
                  value: _vm.form.closeCustomerAt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "closeCustomerAt", $$v)
                  },
                  expression: "form.closeCustomerAt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Ngày kết thúc của giáo viên",
                prop: "closeTeacherAt",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "timestamp",
                  format: "dd/MM/yyyy",
                  type: "date",
                },
                model: {
                  value: _vm.form.closeTeacherAt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "closeTeacherAt", $$v)
                  },
                  expression: "form.closeTeacherAt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Ngày kết thúc đợt đặt sách",
                prop: "closeTeacherAt",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "timestamp",
                  format: "dd/MM/yyyy",
                  type: "date",
                },
                model: {
                  value: _vm.form.closeAt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "closeAt", $$v)
                  },
                  expression: "form.closeAt",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }