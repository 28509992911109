var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        _vm._l(_vm.tableData, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.id, attrs: { label: item.name } },
            [_c("SubjectTable", { attrs: { "grade-id": item.id } })],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }