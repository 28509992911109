var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "top" } },
        _vm._l(_vm.grades, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.id, attrs: { label: item.name } },
            [
              _c("print-ticket-booking-page", {
                attrs: {
                  "strategy-id": _vm.strategyId,
                  grade: item,
                  linkFooter: _vm.linkFooter,
                  linkHeader: _vm.linkHeader,
                  note: _vm.note,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }