var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: 12, xs: 24 } },
            [
              _c(
                "div",
                { staticClass: "card-panel", staticStyle: { height: "120px" } },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-money" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "school", size: "3x" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v("Trường"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tổng lớp:")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouce.totalClassroom,
                          duration: 3200,
                        },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tổng giáo viên:")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouce.totalTeacher,
                          duration: 3200,
                        },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tổng học sinh:")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouce.totalCustomer,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: 12, xs: 24 } },
            [
              _c(
                "div",
                { staticClass: "card-panel", staticStyle: { height: "120px" } },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-money" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "book", size: "3x" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(
                          "Đặt sách " +
                            _vm._s(
                              _vm.dataStrategy.strategy &&
                                _vm.dataStrategy.strategy.name
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tổng sách:")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataStrategy.totalBook,
                          duration: 3200,
                        },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tổng tiền:")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataStrategy.totalMoney,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }