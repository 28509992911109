"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitPreOrderApi = exports.strategyOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var strategyOrderApi = {
  opening: function opening() {
    return (0, _request.default)({
      url: "/strategyOrder/opening"
    });
  },
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/strategyOrder",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/strategyOrder/"
    });
  },
  report: function report(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/report")
    });
  },
  classroomReport: function classroomReport(id, params) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/classrooms"),
      params: params
    });
  },
  classroomDetailReport: function classroomDetailReport(id, classroomId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/classrooms/").concat(classroomId)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/strategyOrder",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  estimate: function estimate(id, data) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/estimate"),
      data: data,
      method: "post"
    });
  },
  submit: function submit(id, data) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/submit"),
      method: "post",
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/delete"),
      method: "post"
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/submit/cancel"),
      method: "post"
    });
  },
  findBooks: function findBooks(id, params) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/books"),
      params: params
    });
  }
};
exports.strategyOrderApi = strategyOrderApi;
var submitPreOrderApi = {
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/submitPreOrder/".concat(id, "/delete"),
      method: "post"
    });
  },
  submit: function submit(id) {
    return (0, _request.default)({
      url: "/preOrder/".concat(id, "/submit"),
      method: "post"
    });
  }
};
exports.submitPreOrderApi = submitPreOrderApi;