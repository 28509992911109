"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AppMain",
  data: function data() {
    return {
      expiredDays: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    userInfo: function userInfo(state) {
      return state.user.info;
    }
  })), {}, {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  methods: {
    checkExpired: function checkExpired() {
      var today = (0, _moment.default)();
      var expired = (0, _moment.default)(this.userInfo.expiredAt * 1000);
      this.expiredDays = expired.diff(today, "days");
      if (this.userInfo.expiredAt != 0 && this.expiredDays <= 10) {
        return true;
      }
      return false;
    }
  }
};
exports.default = _default;