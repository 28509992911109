var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "top" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Tất cả" } },
            [
              _c("classroom-report-table", {
                attrs: {
                  gradeId: undefined,
                  preOrderId: _vm.preOrderId,
                  editable: _vm.editable,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.grades, function (item) {
            return _c(
              "el-tab-pane",
              { key: item.id, attrs: { label: item.name, lazy: "" } },
              [
                _c("classroom-report-table", {
                  attrs: {
                    gradeId: item.id,
                    preOrderId: _vm.preOrderId,
                    editable: _vm.editable,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }