"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _date = require("@/utils/date");
var timeTable = _interopRequireWildcard(require("@/api/time-table"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    classrooms: function classrooms(state) {
      return state["class-room"].list;
    }
  })), (0, _vuex.mapGetters)(["token"])),
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    },
    classroomId: {
      type: Number
    }
  },
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "info":
          this.dialogWidth = "500px";
          break;
        case "Affiliate":
          this.dialogWidth = "800px";
          break;
        default:
          this.dialogWidth = "1300px";
          break;
      }
    },
    classroomId: {
      handler: function handler(val) {
        if (val) {
          this.$set(this.tempData, "classroomId", val);
        }
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.$store.dispatch("class-room/fetchList");
  },
  data: function data() {
    return {
      dialogWidth: "500px",
      tabName: "info",
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      fileList: [],
      typeVehicles: [],
      dialogLoading: false,
      tempData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        title: {
          required: true,
          message: "Vui lòng nhập tiêu đề",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(response, file, fileList) {
      this.loadingModal = false;
      this.fileList = fileList;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 5;
      this.loadingModal = true;
      if (!isJPG) {
        this.$message.error("Image picture must be image format!");
        this.loadingModal = false;
      }
      if (!isLt2M) {
        this.loadingModal = false;
        this.$message.error("Image picture size can not exceed 5MB!");
      }
      return isJPG && isLt2M;
    },
    editData: function editData() {
      var _this = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var images = [];
          var _iterator = (0, _createForOfIteratorHelper2.default)(_this.fileList),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var el = _step.value;
              images.push(el.response ? el.response.data.path : el.url.replace(_this.$baseUrlMedia, ""));
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          var data = {
            timetable: _this.tempData,
            images: images,
            classroomId: _this.tempData.classroomId
          };
          _this.loadingButton = true;
          timeTable.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật thành công"
              });
              _this.dialogVisible = false;
              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();
        _this2.tempData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
          classroomId: data.classroom && data.classroom.id
        });
        _this2.fileList = data.timetableDetails.map(function (e) {
          return {
            url: _this2.$baseUrlMedia + e.thumbnail
          };
        });
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tempData = {};
      this.fileList = [];
      this.$set(this.tempData, "classroomId", this.classroomId);
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var images = [];
          var _iterator2 = (0, _createForOfIteratorHelper2.default)(_this4.fileList),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var el = _step2.value;
              images.push(el.response.data.path);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
          var data = {
            title: _this4.tempData.title,
            images: images,
            classroomId: _this4.tempData.classroomId
          };
          _this4.loadingButton = true;
          timeTable.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo thành công"
              });
              _this4.dialogVisible = false;
              _this4.$refs["dataForm"].clearValidate();
              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;