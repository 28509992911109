var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "margin-bottom": "16px",
          },
        },
        [
          _c(
            "label",
            {
              staticStyle: { "text-transform": "uppercase" },
              attrs: { for: "" },
            },
            [_vm._v("Đợt đặt sách: ")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "300px", "margin-left": "8px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.strategyOrderId,
                callback: function ($$v) {
                  _vm.strategyOrderId = $$v
                },
                expression: "strategyOrderId",
              },
            },
            _vm._l(_vm.strategies, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.strategyOrderId
        ? _c(
            "div",
            [
              _c("summary-strategy", {
                attrs: {
                  "strategy-order": _vm.report.strategyOrder,
                  summary: _vm.report.summary,
                  "total-commission": _vm.totalCommission,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c("summary-tab", {
                    attrs: {
                      "on-change-total-commission":
                        _vm.handleChangeTotalCommission,
                      "on-valid-summary-book": _vm.handleValidSummaryBook,
                      submits: _vm.submits,
                      "pre-order-id": _vm.strategyOrderId,
                      "summary-book": _vm.summaryBook,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                    margin: "0 auto",
                    "margin-top": "16px",
                  },
                },
                [
                  !_vm.submits.length
                    ? [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "8px" },
                            attrs: {
                              loading: _vm.loadingEstimate,
                              type: "primary",
                              disabled: !_vm.summaryBook.length,
                            },
                            on: { click: _vm.estimate },
                          },
                          [_vm._v("\n          Tính hoa hồng\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "8px" },
                            attrs: {
                              loading: _vm.loadingSubmit,
                              type: "primary",
                              disabled: !_vm.summaryBook.length,
                            },
                            on: { click: _vm.handleSubmit },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s("Nộp tất cả") +
                                "\n        "
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "8px" },
                            attrs: {
                              loading: _vm.loadingCancel,
                              type: "danger",
                            },
                            on: { click: _vm.handleCancel },
                          },
                          [_vm._v("\n          Huỷ tất cả\n        ")]
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          )
        : [
            _c(
              "h3",
              { staticClass: "text-center", staticStyle: { color: "red" } },
              [_vm._v("\n      Vui lòng chọn đợt đặt sách\n    ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }