var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _vm.checkExpired()
        ? _c("el-alert", {
            attrs: {
              title:
                _vm.expiredDays <= 0
                  ? "Bạn đã quá hạn thanh toán sổ liên lạc. Vui lòng liên hệ nhà cung cấp để gia hạn."
                  : "Bạn còn " +
                    _vm.expiredDays +
                    " ngày nữa đến hạn thanh toán sổ liên lạc. Vui lòng liên hệ nhà cung cấp để gia hạn.",
              type: "error",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }