var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Chi tiết đơn hàng",
        visible: _vm.visible,
        width: "800px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("ul", { staticStyle: { "padding-left": "15px" } }, [
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Mã đơn:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.code))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Học sinh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.studentName))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Phụ huynh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.parentName))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("SDT:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.parentPhone))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "color-black", attrs: { for: "" } }, [
            _vm._v("Ngày sinh:"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.studentDob))]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.data.preOrderDetails, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên SP", prop: "book.name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thuộc tính" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.bookAttribute1
                      ? _c("div", [
                          _vm._v(
                            "\n          - " +
                              _vm._s(row.book.attribute1) +
                              ": " +
                              _vm._s(row.bookAttribute1.value) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.bookAttribute2
                      ? _c("div", [
                          _vm._v(
                            "\n          - " +
                              _vm._s(row.book.attribute2) +
                              ": " +
                              _vm._s(row.bookAttribute2.value) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Số lượng", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toThousandFilter")(row.quantity)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Giá bán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toThousandFilter")(row.finalPrice)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thành tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            row.finalPrice * row.quantity
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-end" } },
        [
          _c("ul", { staticClass: "detail-order" }, [
            _c("li", [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Tổng số lượng sách:"),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.getTotalQuantity))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tổng tiền:")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm._f("toThousandFilter")(_vm.data.moneyFinal))),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }