var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "preorder-grade" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.data,
            border: "",
            fit: "",
            "row-key": "id",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
        },
        [
          _c("el-table-column", { attrs: { label: "Sách", prop: "name" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đơn giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatNumberVN(row.finalPrice)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SL", prop: "quantityBook" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.visibleSubmit
                      ? _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                            },
                          ],
                          attrs: { disabled: !_vm.editable },
                          on: {
                            change: function ($event) {
                              return _vm.pastQuantity($event, row)
                            },
                          },
                          model: {
                            value: row.quantityBook,
                            callback: function ($$v) {
                              _vm.$set(row, "quantityBook", $$v)
                            },
                            expression: "row.quantityBook",
                          },
                        })
                      : _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$formatNumberVN(row.quantityBook)) +
                              "\n        "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thành tiền", prop: "quantityBook" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$formatNumberVN(row.quantityBook * row.finalPrice)
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }