"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/163_Hebec/163truong/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _schoolContactBook = require("@/api/school-contact-book");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.fetchTableData();
  },
  methods: {
    fetchTableData: function fetchTableData() {
      var _this = this;
      this.listLoading = true;
      _schoolContactBook.schoolContactBookApi.findAll((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        schoolId: this.listQuery.schoolId || this.schoolId
      })).then(function (res) {
        _this.tableData = res.data.schoolContactBookTransactions;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;