var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Lớp" } },
            [
              _c("ClassroomReport", {
                attrs: {
                  "pre-order-id": _vm.strategyOrderId,
                  "cancel-submit": _vm.cancelSubmit,
                  "loading-cancel": _vm.loadingCancel,
                  "is-submitted": _vm.isSubmitted,
                  editable: _vm.editable,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Khối" } },
            [
              _c("SummaryGrade", {
                attrs: { "summary-grade": _vm.report.grades },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Dự trù đầu cấp" } },
            [
              _c("PreOrder", {
                attrs: {
                  isSubmittedStrategy: _vm.isSubmitted,
                  strategyPreOrderId: _vm.strategyOrderId,
                  submits: _vm.submits,
                  editable: _vm.editable,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "In phiếu đặt sách" } },
            [
              _c("print-ticket-booking-page", {
                attrs: { "strategy-id": _vm.strategyOrderId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }